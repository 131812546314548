import gsap from "gsap";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

import AccordionArrowDown from "@/assets/icons/accordion-arrow-down-white.svg";
import AccordionArrowUp from "@/assets/icons/accordion-arrow-up-black.svg";

import styles from "./ThreeColumnExpander.module.scss";

const ThreeColumnExpander = ({
  category,
  description,
  featuredTag,
  id,
  onSelectClass,
  selectedClass,
  title,
}) => {
  const descriptionRef = useRef(null);

  const toggleAnimation = (open) => {
    if (open) {
      gsap.set(descriptionRef.current, { height: "auto", opacity: 1 });

      gsap.from(descriptionRef.current, {
        duration: 0.8,
        height: 0,
        opacity: 0,
      });
    } else {
      gsap.to(descriptionRef.current, {
        duration: 0.8,
        height: 0,
        opacity: 0,
      });
    }
  };

  useEffect(() => {
    if (selectedClass === id) {
      toggleAnimation(true);
    } else {
      toggleAnimation(false);
    }
  }, [id, selectedClass]);

  const active = selectedClass === id;
  const labelValue = `${active ? "Close" : "Open"} ${title}`;

  return (
    <div
      alt={labelValue}
      aria-expanded={active}
      className={`${styles.threeColumnExpander} ${
        active ? styles.threeColumnExpanderActive : ""
      }`}
      data-is="ThreeColumnExpander"
      onClick={() => {
        if (id === selectedClass) {
          return;
        }

        onSelectClass(id);
      }}
      onKeyDown={({ key }) => {
        if (key === "Enter") {
          onSelectClass(id);
        }
      }}
      onMouseEnter={() => {
        if (id === selectedClass) {
          return;
        }

        onSelectClass(id);
      }}
      role="button"
      tabIndex={0}
    >
      <div className={`${styles.contentContainer} container`}>
        <div className="row">
          <div
            className={`${styles.category} ${
              active ? styles.active : ""
            } col-md-5 d-flex align-items-center order-2 order-md-1`}
          >
            <span>{category}</span>
          </div>

          <div
            className={`${styles.title} ${
              active ? styles.active : ""
            } col-14 col-md-7 col-lg-8 order-3 order-md-2`}
          >
            {title}
          </div>

          <div
            className={`${styles.featuredTag} ${
              active ? styles.active : ""
            } col-md-3 col-lg-2 d-flex align-items-center order-1 order-md-3`}
          >
            <span
              className={`${styles.featuredTagBox} ${
                featuredTag ? "d-inline" : "d-none"
              } ${active ? styles.active : ""}`}
            >
              {featuredTag}
            </span>
          </div>

          <div
            className={`${styles.arrowContainer} col-2 col-md-1 d-flex align-items-center order-4 d-lg-none`}
          >
            {active ? <AccordionArrowUp /> : <AccordionArrowDown />}
          </div>
        </div>

        <div className="row">
          <div
            className={`${
              styles.description
            } col-md-8 offset-md-5 col-sm-16 ${active ? styles.active : ""}`}
            ref={descriptionRef}
          >
            {description}
          </div>
        </div>
      </div>

      <div
        className={`${styles.rowBackground} ${active ? styles.rowActive : ""}`}
      ></div>
    </div>
  );
};

ThreeColumnExpander.propTypes = {
  category: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  featuredTag: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onSelectClass: PropTypes.func.isRequired,
  selectedClass: PropTypes.number,
  title: PropTypes.string.isRequired,
};

export default ThreeColumnExpander;
